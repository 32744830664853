import updatePassword from './UpdatePassword.vue'

export default {
  components: {
    updatePassword
  },
  name: 'edit-operator',
  data() {
    return {
      user: {
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password:''
      },
      showPass: false,
      showPass2: false,
      password2: '',
      edit_modal: false,
      load: false,
      edit: false,
      create:false,
      viewMode: false,
      isLoading:false
    }
  },
  methods: {
    activateEdit() {
      this.edit = !this.edit
    },
    createMode(){
      this.user = {
        pk:'',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password:''
      }
      this.password2 = ''
      this.create = true
      this.edit = true
      this.edit_modal = true
    },
    async getUserData(user) {
      this.edit_modal = true
      this.edit = true
      this.user = JSON.parse(JSON.stringify(user))
    },
    close() {
      this.edit_modal = false
      this.edit = false
      this.viewMode = false
      this.create = false
      this.password2 = ''
      this.$refs.form_operator.reset();
    },
    update() {
      this.$refs.form_operator.validate().then(result => {
        if (result) {
          this.axios({
            url:this.create ? this.$store.getters.getUser : this.$store.getters.getOperators(this.user.pk), 
            method: this.create ? 'post' : 'put',
            data:this.user
          })
            .then((response) => {
              if (response.status == 201 || response.status == 200) {
                let dataEdited = Object.assign(this.user)
                this.$emit('close_dialog', dataEdited)
                if(this.create){
                  this.$awn.success(this.$t('messages.created_successfully'))
                } else {
                  this.$awn.success(this.$t('messages.edited_successfully'))
                }
                this.close()
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }
      })
    },
    openEdit () {
      this.$refs.password_modal.open()
    }
  }
}