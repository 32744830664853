export default {
  name: 'update-password',
  props: ['user'],
  data() {
    return {
      password: '',
      new_password: '',
      new_password_repeat: '',
      showPass: false,
      showPass2: false,
      showPass3: false,
      isAdmin: true,
      dialog: false,
      load: false
    }
  },
  methods: {
    open() {
      this.clean()
      this.dialog = true
    },
    clean() {
      this.password = ''
      this.new_password = ''
      this.new_password_repeat = ''
    },
    update() {
      this.$refs.update_password.validate().then(result => {
        if (result) {
          let password = {
            "password": this.password,
            "new_password": this.new_password,
            "new_password_repeat": this.new_password_repeat
          }
          this.axios.put(this.$store.getters.userSetPassword(this.user.pk), password)
            .then((response) => {
              if (response.status == 200) {
                this.close()
                this.$emit('close_dialog_password', true)
                this.$awn.success(this.$t('user.password')+" "+this.$t('messages.edit_succesfully'))
              }
            })
            .catch((error) => {
              console.error(error.response)
            })
        }
      })
    },
    close() {
      this.clean()
      this.dialog = false
    }
  }
}