import EditOperator from '../../components/operator/EditOperator.vue'

export default {
  components: {
    EditOperator
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      total: 0,
      search: '',
      searchingMode: false,
      loading: false,
      lists: [],
      rows: [],
      next: null,
      prev: null
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("user.username"),
          value: "username"
        },
        {
          text: this.$t("user.email"),
          value: "email"
        },
        {
          text: this.$t("user.first_name"),
          value: "first_name"
        },
        {
          text: this.$t("user.last_name"),
          value: "last_name"
        },
        {
          text: this.$t("actions.options"),
          value: "options",
          align: 'center',
          width: 110
        }
      ]
    }
  },
  methods: {
    searchOperator(searchSold) {
      this.search = searchSold
      this.getOperators()
    },
    getOperators() {
      this.loading = true;
      this.axios
        .get(this.$store.getters.getOperators(), {
          params: {
            search: this.search,
            page: this.page,
            size: this.perPage
          }
        })
        .then(response => {
          this.lists = response.results
          this.total = response.count
        })
        .finally(() => {
          this.loading = false;
        })
    },
    close_edit() {
      this.getOperators()
    },
    edit(id) {
      let current_user = this.lists.filter( (obj) => obj.pk == id )
      this.$refs.edit_operator.getUserData(current_user[0])
    },
  },
  mounted() {
    this.getOperators()
  }
};
