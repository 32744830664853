var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","dark":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fa-times")])],1)],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$tc('actions.update'))+" "+_vm._s(_vm.$tc('user.password')))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","small":_vm.$vuetify.breakpoint.smAndDown,"loading":_vm.load},on:{"click":_vm.update},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"color":"white","indeterminate":"","width":"3"}},[_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('$vuetify.icons.loading')}})],1)]},proxy:true}])},[_c('v-icon',[_vm._v("fa-save")])],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('ValidationObserver',{ref:"update_password",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12"}},[(!_vm.isAdmin)?_c('ValidationProvider',{attrs:{"vid":"old_password","name":"old_password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isAdmin)?_c('v-text-field',{attrs:{"name":"old_password","solo":"","label":_vm.$tc('password',1),"error-messages":errors[0],"type":_vm.showPass ? 'text' : 'password',"append-icon":_vm.showPass ? 'fa-eye' : 'fa-eye-slash'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e()]}}],null,true)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('ValidationProvider',{attrs:{"vid":"pw","name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{'secondary--text':true, 'pr-3': _vm.$vuetify.breakpoint.smAndUp},attrs:{"name":"password","label":_vm.$tc('user.password'),"error-messages":errors[0],"type":_vm.showPass2 ? 'text' : 'password',"append-icon":_vm.showPass2 ? 'fa-eye' : 'fa-eye-slash'},on:{"click:append":function($event){_vm.showPass2 = !_vm.showPass2}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password","name":"password_confirmation","rules":"required|confirmed:pw"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password_confirmation","label":_vm.$tc('user.password_repeat'),"error-messages":errors[0],"type":_vm.showPass3 ? 'text' : 'password',"append-icon":_vm.showPass3 ? 'fa-eye' : 'fa-eye-slash'},on:{"click:append":function($event){_vm.showPass3 = !_vm.showPass3}},model:{value:(_vm.new_password_repeat),callback:function ($$v) {_vm.new_password_repeat=$$v},expression:"new_password_repeat"}})]}}],null,true)})],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }